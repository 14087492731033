import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useAuth } from '@/hooks/useAuth';

/**
 * AuthGuard.
 */
const AuthGuard = ({ children, isPublic, redirectTo, router }) => {
  const { pathname } = router;
  const [authorized, setAuthorized] = useState(false);
  const { authenticated, loading } = useAuth();

  useEffect(() => {
    async function checkAuth() {
      const publicWithAuth = isPublic && authenticated;
      const publicWithoutAuth = isPublic && !authenticated;
      const nonPublicWithAuth = !isPublic && authenticated;
      const nonPublicWithoutAuth = !isPublic && !authenticated;

      if (publicWithAuth || nonPublicWithoutAuth) {
        router.push(redirectTo);
      } else if (publicWithoutAuth || nonPublicWithAuth) {
        setAuthorized(true);
      }
    }
    if (loading) {
      return;
    }
    checkAuth();
  }, [pathname, isPublic, redirectTo, router, loading]);

  if (authorized) {
    return children;
  }

  return null;
};

AuthGuard.propTypes = {
  children: PropTypes.any,
  router: PropTypes.object.isRequired,
  isPublic: PropTypes.bool,
  redirectTo: PropTypes.string,
};

AuthGuard.defaultProps = {
  children: '',
  isPublic: false,
  redirectTo: '/login',
};

export default withRouter(AuthGuard);
