import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Input from '@/components/ui/Input/Input';
import Loader from '@/components/ui/Loader/Loader';
import PageTopMarginWrapper from '@/components/ui/PageTopMarginWrapper/PageTopMarginWrapper';
import api from '@/helpers/api';
import { EMAIL_REGEX } from '@/helpers/validators';
import useFormatMessage from '@/hooks/useFormatMessage';

import {
  Button,
  EmailContainer,
  MainHeading,
  TextContent,
} from './AuthScreen.style';
import Success from './lib/Success';

/**
 * AuthScreen.
 */
export const AuthScreen = ({ refererPathname }) => {
  const formatMessage = useFormatMessage();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    getValues,
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const [submitSuccessful, setSubmitSuccessful] = useState(false);

  const sendEmail = async values => {
    await api.createToken({
      email: values.email,
      refererPathname,
    });

    setSubmitSuccessful(true);
  };

  const renderEmailForm = () => {
    return (
      <>
        <Stack
          alignItems="center"
          px={2}
          component="form"
          onSubmit={handleSubmit(sendEmail)}>
          <MainHeading variant="h2" component="h1" align="center" mb={3}>
            {formatMessage('authScreen_headline')}
          </MainHeading>
          <TextContent variant="body1">
            {formatMessage('authScreen_subline')}
          </TextContent>
          <EmailContainer>
            <Typography variant="body2" mb={1.5}>
              {formatMessage('authScreen_request')}
            </Typography>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  message: 'authScreen_invalidEmail',
                  value: EMAIL_REGEX,
                },
              }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <Input
                  inputMode="email"
                  fullWidth
                  label={formatMessage('authScreen_email')}
                  error={!!error && isTouched}
                  helperText={
                    isTouched && error && error.message
                      ? formatMessage(error.message)
                      : ' '
                  }
                  required
                  {...field}
                />
              )}
            />
            <Button
              type="submit"
              size="large"
              disabled={!isDirty || !isValid || isSubmitting}>
              {formatMessage('authScreen_send')}
            </Button>
          </EmailContainer>
        </Stack>
        <Loader show={isSubmitting} />
      </>
    );
  };

  return (
    <PageTopMarginWrapper navigationHidden>
      {submitSuccessful ? (
        <Success email={getValues('email')} />
      ) : (
        renderEmailForm()
      )}
    </PageTopMarginWrapper>
  );
};

AuthScreen.propTypes = {
  refererPathname: PropTypes.string,
};

export default AuthScreen;
