import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import BaseButton from '@/components/ui/Button/Button';
import Paper from '@/components/ui/Paper/Paper';

export const MainHeading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(24),
  },
}));

export const TextContent = styled(Typography)(({ theme }) => ({
  width: 482,
  marginBottom: theme.spacing(3),
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const Button = styled(BaseButton)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginTop: theme.spacing(0.5),
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
}));

export const EmailContainer = styled(Paper)(({ theme }) => ({
  width: 682,
  minHeight: 180,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 3.5),

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));
