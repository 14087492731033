import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import EmailSent from '@/assets/email-sent.svg';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import Paper from '@/components/ui/Paper/Paper';
import useFormatMessage from '@/hooks/useFormatMessage';

import { Title } from './Success.style';

const Success = ({ email }) => {
  const formatMessage = useFormatMessage();

  return (
    <PageMargins>
      <Paper
        component={Stack}
        alignItems="center"
        pt={{ md: 9, xs: 4 }}
        pb={{ md: 19, xs: 4 }}
        px={2}>
        <EmailSent />
        <Box width={{ xs: '100%', md: 485 }}>
          <Title
            variant="h2"
            component="h1"
            align="center"
            my={{ md: 2.5, xs: 1.5 }}>
            {formatMessage('authScreen_successHead')}
          </Title>
          <Typography variant="body1" align="center">
            {formatMessage('authScreen_successSub', { email })}
          </Typography>
        </Box>
      </Paper>
    </PageMargins>
  );
};

Success.propTypes = {
  email: PropTypes.string,
};

Success.defaultProps = {
  email: '',
};

export default Success;
