import * as Sentry from '@sentry/nextjs';
import React from 'react';

import AuthGuard from '@/components/AuthGuard/AuthGuard';
import AuthScreen from '@/components/AuthScreen/AuthScreen';
import SimplifiedLayout from '@/layouts/simplified';

const Login = ({ refererPathname }) => (
  <SimplifiedLayout showB2BModal={false}>
    <AuthGuard isPublic redirectTo="/bookings">
      <AuthScreen refererPathname={refererPathname} />
    </AuthGuard>
  </SimplifiedLayout>
);

export async function getServerSideProps(ctx) {
  const referer = ctx.req.headers?.referer;
  let refererPathname;

  if (referer) {
    try {
      refererPathname = new URL(referer).pathname;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  return {
    props: {
      refererPathname: refererPathname || null,
    },
  };
}

export default Login;
